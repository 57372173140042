import { graphql, useStaticQuery } from 'gatsby';

import { SEO } from 'voom-gatsby';

import { LegalTemplate } from '~sections/LegalTemplate';

export const PATENTS_QUERY = graphql`
  query Patents {
    # sanityVoomLegal(title: { eq: "Patents" }) {
    sanityVoomLegal(_id: { eq: "a3bea921-3f39-4a5a-aa77-5f086419b9b6" }) {
      id
      title
      effectiveDate(formatString: "MMMM DD, YYYY")
      _rawFirstSection
      _rawContent
    }
  }
`;

const Patents = () => {
  const data = useStaticQuery(PATENTS_QUERY);

  return <LegalTemplate {...data.sanityVoomLegal} />;
};

export const Head = () => {
  return (
    <SEO
      title="Patents"
      description="The following VoomTM Medical Devices products are protected by one or more U.S. patents as well as their international equivalents."
    />
  );
};

export default Patents;
