import { graphql, useStaticQuery } from 'gatsby';

import { SEO } from 'voom-gatsby';

import { LegalTemplate } from '~sections/LegalTemplate';

export const PATIENT_RISK_QUERY = graphql`
  query PatientRisk {
    # sanityVoomLegal(title: { eq: "Patient Risk Information" }) {
    sanityVoomLegal(_id: { eq: "8fd6ecae-b4c5-46de-898f-f4fc47129691" }) {
      id
      title
      effectiveDate(formatString: "MMMM DD, YYYY")
      _rawFirstSection
      _rawContent
    }
  }
`;

const PatientRisk = () => {
  const data = useStaticQuery(PATIENT_RISK_QUERY);

  return <LegalTemplate {...data.sanityVoomLegal} />;
};

export const Head = () => {
  return (
    <SEO
      title="Patient Risk Information"
      description="At Voom Medical Devices, Dr. Neal Blitz is revolutionizing foot and ankle surgery, including Bunionplasty™ and bunion repair, with innovative medical devices and products."
    />
  );
};

export default PatientRisk;
