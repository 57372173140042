import { graphql, useStaticQuery } from 'gatsby';

import { SEO } from 'voom-gatsby';

import { LegalTemplate } from '~sections/LegalTemplate';

export const PRIVACY_POLICY_QUERY = graphql`
  query PrivacyPolicy {
    # sanityVoomLegal(title: { eq: "Privacy Policy" }) {
    sanityVoomLegal(_id: { eq: "e0b1e71e-8d5a-48a5-a68a-6ea11dccb0d1" }) {
      id
      title
      effectiveDate(formatString: "MMMM DD, YYYY")
      _rawFirstSection
      _rawContent
    }
  }
`;

const PrivacyPolicy = () => {
  const data = useStaticQuery(PRIVACY_POLICY_QUERY);

  return <LegalTemplate {...data.sanityVoomLegal} />;
};

export const Head = () => {
  return (
    <SEO
      title="Privacy Policy"
      description="At Voom Medical Devices, Dr. Neal Blitz is revolutionizing foot and ankle surgery, including Bunionplasty™ and bunion repair, with innovative medical devices and products."
    />
  );
};

export default PrivacyPolicy;
