exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-leadership-tsx": () => import("./../../../src/pages/about-us/leadership.tsx" /* webpackChunkName: "component---src-pages-about-us-leadership-tsx" */),
  "component---src-pages-about-us-news-events-tsx": () => import("./../../../src/pages/about-us/news-events.tsx" /* webpackChunkName: "component---src-pages-about-us-news-events-tsx" */),
  "component---src-pages-bunionplasty-tsx": () => import("./../../../src/pages/bunionplasty.tsx" /* webpackChunkName: "component---src-pages-bunionplasty-tsx" */),
  "component---src-pages-compliance-tsx": () => import("./../../../src/pages/compliance.tsx" /* webpackChunkName: "component---src-pages-compliance-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-patents-tsx": () => import("./../../../src/pages/patents.tsx" /* webpackChunkName: "component---src-pages-patents-tsx" */),
  "component---src-pages-patient-risk-tsx": () => import("./../../../src/pages/patient-risk.tsx" /* webpackChunkName: "component---src-pages-patient-risk-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-revcon-screw-tsx": () => import("./../../../src/pages/revcon-screw.tsx" /* webpackChunkName: "component---src-pages-revcon-screw-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-train-with-us-tsx": () => import("./../../../src/pages/train-with-us.tsx" /* webpackChunkName: "component---src-pages-train-with-us-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article-template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-train-template-tsx": () => import("./../../../src/templates/train-template.tsx" /* webpackChunkName: "component---src-templates-train-template-tsx" */)
}

